import React from "react";
import CTA from "../CTA/cta";
import Container from "../Container/container";

const endOfPageLinks = {
	combos: {
		to: "/collections/baloomba-yak-chews",
		title: "Shop Chews",
	},
	tearribles: {
		to: "/products/combos",
		title: "Shop Combos",
	},
	chews: {
		to: "/products/combos",
		title: "Shop Combos",
	},
	gear: {
		to: "/products/combos",
		title: "Shop Combos",
	},
};

export function NextCollection(props) {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

	if (domain !== "tearribles.myshopify.com") {
		return null;
	}

	const collection = props.data.allShopifyCollection.edges[0]?.node;

	if (!collection) {
		return null;
	}

	const handle = props.handleOverride || collection.handle;

	if (!handle) {
		console.log(handle);
		return null;
	}

	if (!endOfPageLinks[handle]) {
		return null;
	}

	return (
		<Container style={{ display: "flex", justifyContent: "center" }}>
			<CTA
				to={endOfPageLinks[handle].to}
				title={endOfPageLinks[handle].title}
			/>
		</Container>
	);
}
