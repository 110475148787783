import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import ProductSelect from "../../components/ProductSelect/product-select";
import { NextCollection } from "../../components/ProductSelect/next-collection";

class GearSelect extends Component {
	render() {
		return (
			<>
				<ProductSelect title="Gear" {...this.props} />
				<NextCollection {...this.props} />
			</>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query gearQuery {
				allShopifyCollection(filter: { title: { in: "Gear" } }) {
					edges {
						node {
							title
							handle
							products {
								id
								shopifyId
								legacyResourceId
								title
								descriptionHtml
								productType
								tags
								handle
								collections {
									title
								}
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									title
									id
									shopifyId
									legacyResourceId
									sku
									price
									compareAtPrice
									availableForSale
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <GearSelect data={data} {...props} />}
	</StaticQuery>
);
